import {
  ChartDataset,
  ScatterDataPoint,
  ScriptableLineSegmentContext,
} from 'chart.js';
import type {
  AccountDocument,
  AccountsById,
  BudgetDocument,
  BudgetsById,
  DataPoint,
  Projection,
  ProjectionWithAvailable,
  TransactionDocument,
  TransactionDocumentWithId,
  BudgetViewDocument,
  BudgetGroup,
} from '@og-shared/types';
import { addHours } from 'date-fns';
import {
  isBankTransaction,
  sortTransactions,
  calcFrequencyFromBudget,
  shouldIncludeBudgetTransactionBasedOnStartAndEndDates,
  getNextBudgetTransactionDateAfterMinDate,
  getDateAtMidnight,
  getNextDate,
  formatDateFromString,
  dictionaryToArray,
  isPresent,
  keys,
  budgetNotTracked,
  IGNORE_BUDGET_ID,
  exhaustiveCheck,
  BudgetType,
  INCOME_BUDGET_ID,
  getAccountDisplayNumber,
  shouldFundBudgetToday,
  TRANSFER_SUBTYPE,
  calcTotalAvailable,
} from '@og-shared/utils';

import { budgetTypes } from '../services/static-data';
import {
  getColor,
  getIonColorRgba,
  getTextColor,
  primaryColor,
} from './colors';
import { translate } from './translate';
import { getTomorrowDateString } from './date-utils';
import { getAccountName } from './account-utils';
import { getGroupByKeyFromDate } from './past-transactions';
import { isFuture } from './utils';

type GroupBy = 'year' | 'month' | 'week' | 'day';

export const INCOME_AXIS_ID = 'income' as const;
export const EXPENSE_AXIS_ID = 'expense' as const;
export const CASH_FLOW_AXIS_ID = 'cash-flow' as const;

function getProjectedTransactions(params: {
  budgetsById: BudgetsById;
  start: string;
  end: string;
  todayString: string;
}) {
  const { budgetsById: budgetsByIdO, start, end, todayString } = params;
  const budgetsById = JSON.parse(JSON.stringify(budgetsByIdO)) as BudgetsById;
  let projections: Projection[] = [];
  keys(budgetsById).map(budget_id => {
    if (budgetNotTracked(budget_id)) return;
    const budget = budgetsById[budget_id];
    if (!budget) return;
    let dateString = start;

    if (shouldIncludeToday(budget) && end > todayString) {
      const todayProjection: Projection = {
        date: todayString,
        past_due: false,
        amount: 0,
        icon: 'flash',
        color: 16,
        label: translate('TODAY'),
        projection_type: 'today',
        budget_id,
        budget_type: budget.type,
      };
      projections.push(todayProjection);
    }

    // 1. set any missing next transaction dates
    if (
      !budget.next_transaction_date &&
      budget.budget_id !== INCOME_BUDGET_ID &&
      budget.per_year !== 0
    ) {
      budget.next_transaction_date = getNextDate({
        dateString: dateString,
        perYear: budget.per_year,
        days: budget.transaction_days,
      });
    }

    // 2. set any past due bill/income to happen today
    let nextTransactionDate: string | null =
      budget.next_transaction_date ?? null;
    if (
      isPresent(nextTransactionDate) &&
      todayString > nextTransactionDate &&
      budget.budget_id !== INCOME_BUDGET_ID
    ) {
      // if budgets are already due
      // add to today's projection
      // and increase next due date
      while (nextTransactionDate < todayString) {
        if (budget.type === BudgetType.SPENDING && budget.per_year !== 0) {
          // set next due date - and skip the rest
          budget.next_transaction_date =
            getNextBudgetTransactionDateAfterMinDate({
              budget: {
                next_transaction_date: nextTransactionDate,
                per_year: budget.per_year,
                transaction_days: budget.transaction_days,
              },
              minDateString: todayString,
            });
          break;
        }
        const amount =
          budget.type === BudgetType.INCOME
            ? budget.budget
            : budget.budget * -1;

        const item: Projection = {
          past_due: true,
          date: todayString,
          amount,
          label: translate('BUDGET_PAST_DUE', {
            name: budget.name,
            nextDate: formatDateFromString(nextTransactionDate),
          }),
          projection_type: 'future',
          budget_type: budget.type,
          account_id: budget.account_id,
          color: budget.color,
          icon: budget.icon,
          budget_id,
        };
        if (item.amount !== 0) {
          projections.push(item);
        }
        if (budget.type !== BudgetType.INCOME) {
          budget.available = budget.available + item.amount;
        }
        if (budget.per_year === 0) {
          nextTransactionDate = null;
          // one time happened - no more
          if (budget.type !== BudgetType.INCOME) {
            budget.funding_amount = 0; // stop funding
          }
        } else {
          nextTransactionDate = getNextBudgetTransactionDateAfterMinDate({
            budget: {
              next_transaction_date: nextTransactionDate,
              per_year: budget.per_year,
              transaction_days: budget.transaction_days,
            },
            minDateString: nextTransactionDate,
          });
        }

        if (budget.type !== BudgetType.INCOME && budget.available < 0) {
          // pull from income if not enough in category
          const [fromIncome, toBudget] = getStealFromIncome({
            budget,
            projection: item,
            date: dateString,
          });
          projections.push(fromIncome);
          projections.push(toBudget);
          budget.available = 0;
        }
        budget.next_transaction_date = nextTransactionDate;
        if (nextTransactionDate === null) break;
        // console.log('set any past due budget to happen today', budget);
      }
    }

    // 3-4. loop day by day to project upcoming transactions and fills
    while (dateString <= end) {
      // 3. add funding projections for budgets
      const fundingProjection = getFundingFromBudget({
        budget,
        dateString,
        todayString,
      });

      if (fundingProjection && budget.type !== BudgetType.INCOME) {
        budget.available = budget.available + fundingProjection.amount;
        projections.push(fundingProjection);
        const incomeProjection: Projection = {
          past_due: false,
          amount: -fundingProjection.amount,
          projection_type: 'future-fill',
          date: fundingProjection.date,
          label: fundingProjection.label,
          icon: 'flash',
          color: 7,
          budget_type: BudgetType.INCOME,
          budget_id: INCOME_BUDGET_ID,
        };
        projections.push(incomeProjection);
      }

      // 4. add projected budget transactions

      if (shouldIncludeProjection(dateString, budget)) {
        const item = getProjectionFromBudget(budget, dateString);

        projections.push(item);
        if (budget.type !== BudgetType.INCOME) {
          budget.available = budget.available + item.amount;
        }
        if (budget.per_year === 0) {
          if (budget.type !== BudgetType.INCOME) {
            budget.funding_amount = 0; // stop funding
          }
        } else {
          budget.next_transaction_date =
            getNextBudgetTransactionDateAfterMinDate({
              budget: {
                next_transaction_date: budget.next_transaction_date!, // is string from shouldIncludeProjection
                per_year: budget.per_year,
                transaction_days: budget.transaction_days,
              },
              minDateString: dateString,
            });
        }

        if (budget.type !== BudgetType.INCOME && budget.available < 0) {
          // pull from income if not enough in category
          const [fromIncome, toBudget] = getStealFromIncome({
            budget,
            projection: item,
            date: dateString,
          });
          projections.push(fromIncome);
          projections.push(toBudget);
          budget.available = 0;
        }
      }

      const nextDateString = getTomorrowDateString(dateString);
      if (dateString === nextDateString) {
        // prevents infinite loops if our add date code is bad :(
        console.error('failed to add one day', dateString, nextDateString);
        break;
      }
      dateString = nextDateString;
    }
  });

  // 5. sort by date
  projections = projections.sort((a, b) => {
    if (a.date < b.date) {
      return -1;
    } else if (a.date > b.date) {
      return 1;
    } else if (a.projection_type === 'today') {
      return -1;
      // do today, then fill first
    } else if (a.projection_type === 'future-fill') {
      // if same date - do fills first
      return -1;
    } else if (a.projection_type === 'future-move') {
      // if same date - do future move after - so we can see steals in the right order
      return 1;
    } else if (a.past_due && !b.past_due) {
      // sort past due dates first
      return -1;
    } else {
      return 0;
    }
  });
  // fills need to be included,
  // all income sources need to be included
  // by the time they get here they are combined and sorted
  //
  // Now we can sort through and project available budget balances
  // 6. add end
  keys(budgetsById).map(budget_id => {
    const budget = budgetsById[budget_id];
    const endBalance: Projection = {
      past_due: false,
      date: end,
      amount: 0,
      icon: 'flash',
      color: 16,
      label: translate('END'),
      projection_type: 'end',
      budget_type: budget.type,
      budget_id,
    };
    if (budget.type === BudgetType.INCOME) {
      if (budget.budget_id === INCOME_BUDGET_ID) {
        // for income - only include end if it's the INCOME_BUDGET_ID budget
        // all incomes are included in INCOME_BUDGET_ID's projections, so only include one
        projections.push(endBalance);
      }
    } else {
      projections.push(endBalance);
    }
  });

  return projections;
}

function getStealFromIncome(params: {
  budget: Pick<BudgetDocument, 'available' | 'name' | 'is_envelope'>;
  projection: Pick<Projection, 'icon' | 'color' | 'budget_type' | 'budget_id'>;
  date: string;
}) {
  const { date, budget, projection } = params;
  const moveFromIncome: Projection = {
    past_due: false,
    amount: budget.available,
    projection_type: 'future-move',
    date,
    label: budget.is_envelope
      ? translate('FROM_BUDGET_TO_BUDGET', {
          from: translate('INCOME_SAVED'),
          to: budget.name,
        })
      : budget.name,
    icon: 'flash',
    color: 7,
    budget_type: BudgetType.INCOME,
    budget_id: INCOME_BUDGET_ID,
  };
  const moveToBudget: Projection = {
    past_due: false,
    amount: -budget.available,
    projection_type: 'future-move',
    date,
    label: translate('FROM_BUDGET_TO_BUDGET', {
      from: translate('INCOME_SAVED'),
      to: budget.name,
    }),
    icon: projection.icon,
    color: projection.color,
    budget_type: projection.budget_type,
    budget_id: projection.budget_id,
  };
  return [moveFromIncome, moveToBudget];
}

function shouldIncludeProjection(dateString: string, budget: BudgetDocument) {
  return (
    dateString === budget.next_transaction_date &&
    shouldIncludeBudgetTransactionBasedOnStartAndEndDates(budget, dateString) &&
    (budget.budget !== 0 || // don't include zero budgets unless they are transfers
      budget.subtype === TRANSFER_SUBTYPE)
  );
}

export function getProjectedTransactionsByBudgetId(params: {
  budgetsById: BudgetsById;
  start: string;
  end: string;
  todayString: string;
}) {
  const { budgetsById, start, end, todayString } = params;
  const availableByBudgetId: { [budget_id: string]: number } = {};
  keys(budgetsById).map(budget_id => {
    availableByBudgetId[budget_id] = budgetsById[budget_id]?.available ?? 0;
  });
  const projections =
    end > todayString
      ? getProjectedTransactions({
          budgetsById,
          start,
          end,
          todayString,
        })
      : [];
  return getProjectionsByBudgetId(
    availableByBudgetId,
    budgetsById,
    projections
  );
}

function shouldIncludeToday(budget: BudgetDocument) {
  return (
    budget.budget_id === INCOME_BUDGET_ID || budget.type !== BudgetType.INCOME
  );
}

function getProjectionsByBudgetId(
  availableByBudgetId: { [budget_id: string]: number },
  budgets_by_id: BudgetsById,
  projections: Projection[]
) {
  const projectionsByBudgetId: {
    [budget_id: string]: ProjectionWithAvailable[];
  } = {};

  projections.map(projection => {
    const budget_id = getProjectionBudgetId(
      projection.budget_id,
      budgets_by_id
    );
    if (!budget_id) return;
    if (!projectionsByBudgetId[budget_id]) {
      projectionsByBudgetId[budget_id] = [];
    }
    const available =
      (availableByBudgetId[budget_id] ?? 0) + projection.amount || 0;
    availableByBudgetId[budget_id] = available;

    projectionsByBudgetId[budget_id].push({ ...projection, available });
  });

  return projectionsByBudgetId;
}

function getProjectionBudgetId(
  originalBudgetId: string,
  budgets_by_id: BudgetsById
) {
  // only used for income budgets - to project income
  let budget_id = originalBudgetId;

  const budget = budgets_by_id[budget_id];
  if (!budget) return null;

  if (budget.type === BudgetType.INCOME) {
    budget_id = INCOME_BUDGET_ID;
  }

  return budget_id;
}

function getProjectedTransactionsByAccountId(params: {
  accountsById: AccountsById;
  past: Projection[];
  future: Projection[];
  end: string;
  todayString: string;
}) {
  const {
    accountsById: accountsByIdO,
    past,
    future,
    end,
    todayString,
  } = params;
  const accountsById: AccountsById = JSON.parse(JSON.stringify(accountsByIdO));
  const projectionsByAccountId: {
    [account_id: string]: ProjectionWithAvailable[];
  } = {};

  keys(accountsById).map(account_id => {
    // add today's balance for each account;
    // const account = accountsById[account_id];
    const available = getAccountDisplayNumber(accountsById[account_id]);
    const pastForAccount = past.filter(p => p.account_id === account_id);
    const pastWithAvailable = convertPastProjectionsToWithAvailable(
      pastForAccount,
      available
    );
    projectionsByAccountId[account_id] = pastWithAvailable;
    if (end > todayString) {
      const todaysBalance: ProjectionWithAvailable = {
        past_due: false,
        date: todayString,
        amount: 0,
        icon: 'flash',
        color: 16,
        label: translate('TODAY'),
        budget_type: null as any, // FIXME
        projection_type: 'today',
        budget_id: null as any, // FIXME
        available,
      };
      projectionsByAccountId[account_id].push(todaysBalance);
    }
  });

  future.map(projection => {
    const account_id = projection.account_id;
    if (!account_id) return;
    if (!projectionsByAccountId[account_id]) {
      projectionsByAccountId[account_id] = [];
    }
    const accountProjections = getAccountProjectionsWithAvailableFromProjection(
      accountsById,
      projection
    );
    accountProjections.map(a => {
      accountsById[a.account.account_id] = a.account;
      if (!projectionsByAccountId[a.account.account_id]) {
        projectionsByAccountId[a.account.account_id] = [];
      }
      projectionsByAccountId[a.account.account_id].push(
        a.projectionWithAvailable
      );
    });
  });
  keys(accountsById).map(account_id => {
    const projectionsWithAvailable = projectionsByAccountId[account_id];
    let available = getAccountDisplayNumber(accountsById[account_id]);
    const lastIndex = projectionsWithAvailable.length - 1;
    if (projectionsWithAvailable[lastIndex]?.available) {
      // set last datapoint on graph as the same available as the last projection
      available = projectionsWithAvailable[lastIndex].available;
    }
    const endBalance: ProjectionWithAvailable = {
      past_due: false,
      date: end,
      amount: 0,
      icon: 'flash',
      color: 16,
      label: translate('END'),
      projection_type: 'end',
      budget_type: null as any, // FIXME
      budget_id: null as any, // FIXME
      available,
    };
    projectionsByAccountId[account_id] = [
      ...projectionsWithAvailable,
      endBalance,
    ];
  });
  return projectionsByAccountId;
}

function getAccountProjectionsWithAvailableFromProjection(
  accountsById: AccountsById,
  projection: Projection
) {
  const accountProjections: {
    projectionWithAvailable: ProjectionWithAvailable;
    account: AccountDocument;
  }[] = [];
  // @todo here we could divide up interest and principle based on account type
  // but only if it's a deposit into an account that is a loan
  let fromAccount = accountsById[`${projection.account_id}`];
  let toAccount = accountsById[`${projection.to_account_id}`];

  if (
    projection.projection_type === 'future-transfer' &&
    toAccount &&
    fromAccount
  ) {
    let amount = getAccountDisplayNumber(toAccount) * -1; // pay off to account in full

    if (
      projection.date === toAccount.next_payment_due_date &&
      toAccount.next_payment_amount
    ) {
      // if not paid in full
      amount = toAccount.next_payment_amount;
    }

    toAccount = updateAccountByAmount(toAccount, amount);
    projection.amount = amount;
    const projectionWithAvailable: ProjectionWithAvailable = {
      ...projection,
      available: getAccountDisplayNumber(toAccount),
    };
    accountProjections.push({
      projectionWithAvailable,
      account: toAccount,
    });

    fromAccount = updateAccountByAmount(fromAccount, -amount);
    projection.amount = -amount;
    const projectionWithAvailableFrom: ProjectionWithAvailable = {
      ...projection,
      available: getAccountDisplayNumber(fromAccount),
    };
    accountProjections.push({
      projectionWithAvailable: projectionWithAvailableFrom,
      account: fromAccount,
    });
    return accountProjections;
  }
  if (fromAccount) {
    fromAccount = updateAccountByAmount(fromAccount, projection.amount);
    const projectionWithAvailable: ProjectionWithAvailable = {
      ...projection,
      available: getAccountDisplayNumber(fromAccount),
    };
    accountProjections.push({
      projectionWithAvailable,
      account: fromAccount,
    });
  }
  return accountProjections;
}

function updateAccountByAmount(account: AccountDocument, amount: number) {
  if (account.type === 'credit' || account.type === 'loan') {
    const available = account.current;
    const newBalance = available + amount || 0;
    account.current = newBalance;
  } else {
    const available = account.available;
    const newBalance = available + amount || 0;
    account.available = newBalance;
  }
  return account;
}

export function getProjectionsForAllAccounts(params: {
  startingBalance: number;
  budgetsById: BudgetsById;
  endDate: string;
  todayString: string;
}) {
  const { startingBalance, budgetsById, endDate, todayString } = params;

  const projections =
    endDate > todayString
      ? getProjectedTransactions({
          budgetsById,
          start: todayString,
          end: endDate,
          todayString,
        })
      : [];
  let available = startingBalance || 0;
  return projections
    .filter(
      p =>
        (p.projection_type === 'future' ||
          p.projection_type === 'future-transfer') &&
        p.amount !== 0
    )
    .map(projection => {
      // @todo here we could divide up interest and principle based on account type
      available = available + (projection.amount || 0);
      const projectionWithAvailable: ProjectionWithAvailable = {
        ...projection,
        available,
      };
      return projectionWithAvailable;
    });
}

function getFundingFromBudget(params: {
  budget: BudgetDocument;
  dateString: string;
  todayString: string;
}) {
  const { budget, dateString, todayString } = params;

  if (dateString === todayString) {
    // don't add fill day for today - it already happened
    return false;
  }

  if (budget.type === BudgetType.INCOME) {
    // income isn't funded
    return false;
  }

  // if (!shouldIncludeBudgetTransactionBasedOnStartAndEndDates(budget, dateString)) {
  //   return false;
  //   funding isn't affected by start/end dates for transactions
  //   we can start/end funding even without a next_transaction date
  //
  // }

  if (!shouldFundBudgetToday(budget, dateString)) {
    return false;
  }

  const amount = budget.funding_amount ?? 0;

  if (!amount) {
    return false;
  }
  const item: Projection = {
    past_due: false,
    amount,
    date: dateString,
    icon: budget.icon,
    color: budget.color,
    label: translate('FUND_NAME', { name: budget.name }),
    account_id: null,
    projection_type: 'future-fill',
    budget_id: budget.budget_id,
    budget_type: budget.type,
  };
  return item;
}

function getProjectionFromBudget(budget: BudgetDocument, dateString: string) {
  const amount =
    budget.type === BudgetType.INCOME ? budget.budget : budget.budget * -1;

  const futureTransfer =
    budget.type === BudgetType.BILL &&
    budget.subtype === TRANSFER_SUBTYPE &&
    budget.to_account_id
      ? true
      : false;

  const item: Projection = {
    past_due: false,
    amount,
    date: dateString,
    icon: budget.icon,
    color: budget.color,
    label: budget.name,
    account_id: budget.account_id,
    projection_type: futureTransfer ? 'future-transfer' : 'future',
    budget_id: budget.budget_id,
    budget_type: budget.type,
    to_account_id: budget.to_account_id,
  };
  return item;
}

function getDateStringForProjection(transaction: Projection) {
  const date = getDateAtMidnight(transaction.date);
  const { projection_type } = transaction;
  if (projection_type === 'today' || projection_type === 'past') {
    // past/today transactions can happen at 8AM that day on the graph
    return addHours(date, 8).toISOString();
  } else if (projection_type === 'future-fill') {
    // always show fill happening first - since it happens at midnight
    return date.toISOString();
  } else if (
    projection_type === 'future' ||
    projection_type === 'future-transfer' ||
    projection_type === 'future-move'
  ) {
    // future transactions happen at 12pm to be after today
    return addHours(date, 12).toISOString();
  }
  // else if (projection_type === 'future-move') {
  //   // show future move happening after future - so we can see the "Steal" after the negative
  //   return addHours(date, 16).toISOString();
  // }
  else if (projection_type === 'end') {
    // end can happen at end of day on the graph
    return addHours(date, 23.9).toISOString();
  }
  exhaustiveCheck(projection_type);
  return addHours(date, 8).toISOString();
}

export function getGraphDataForBudget(options: {
  budgetId?: string;
  budgetsById: BudgetsById;
  start: string;
  end: string;
  todayString: string;
  groupBy?: GroupBy;
}) {
  const {
    groupBy = 'day',
    budgetsById,
    budgetId,
    start,
    end,
    todayString,
  } = options; // @todo pass param for budget graph too
  if (!options.budgetsById) {
    return {
      projections: [],
      datasets: [],
    };
  }
  const projectionsByBudgetId = getProjectedTransactionsByBudgetId({
    budgetsById,
    start,
    end,
    todayString,
  });
  if (budgetId && projectionsByBudgetId[budgetId]) {
    const dataset = getDatasetFromProjections(
      budgetsById[budgetId]?.name,
      budgetsById[budgetId]?.color,
      projectionsByBudgetId[budgetId],
      groupBy
    );
    return {
      projections: projectionsByBudgetId[budgetId],
      datasets: dataset ? [dataset] : [],
    };
  } else {
    const datasets = keys(projectionsByBudgetId).map(id =>
      getDatasetFromProjections(
        budgetsById[id]?.name,
        budgetsById[id]?.color,
        projectionsByBudgetId[id],
        groupBy
      )
    );

    return {
      projections: [], //projectionsByBudgetId[options.budget_id],
      datasets,
    };
  }
}

function getDatasetFromProjections(
  label: string,
  lineColor: number,
  projections: ProjectionWithAvailable[],
  groupBy: GroupBy
) {
  const color = getColor(lineColor, 'primary') || primaryColor;

  if (!projections) {
    const blank: ChartDataset<'line', ScatterDataPoint[]> = {
      data: [],
    };
    return blank;
  }
  const dateMap = getDateMapFromProjections(projections, groupBy);
  const data: DataPoint[] = [];
  const dates = keys(dateMap).sort((a, b) => (a > b ? 1 : -1));
  dates.map(dateString => {
    let available = 0;
    const dateMetadata: DataPoint['metadata'] = [];

    dateMap[dateString].transactions.map(p => {
      available = p.available;
      dateMetadata.push({
        label: p.label,
        value: p.amount,
        color: getColor(p.color, 'primary'),
      });
    });
    if (dateMetadata.length) {
      const pastDataPoint: DataPoint = {
        x: getDateAtMidnight(dateString).getTime(),
        y: available,
        metadata: dateMetadata,
      };
      data.push(pastDataPoint);
    }
  });

  const lineColorNegative = getIonColorRgba('danger', 1);
  // const backgroundColorPastPositive = getColor(lineColor, 'rgba', 0.8);
  const backgroundColorFuturePositive = getColor(lineColor, 'rgba', 0.2);
  // const backgroundColorPastNegative = getIonColorRgba('danger', 0.8);
  const backgroundColorFutureNegative = getIonColorRgba('danger', 0.2);

  const dataset: ChartDataset<'line', ScatterDataPoint[]> = {
    type: 'line',
    label,
    data,
    backgroundColor: color,
    borderColor: color,
    fill: {
      below: backgroundColorFutureNegative,
      above: backgroundColorFuturePositive,
      target: 'origin',
    },
    pointRadius: 0,
    segment: {
      borderColor: ctx => (isNegative(ctx) ? lineColorNegative : color),
      borderWidth: ctx => (isFuture(ctx) ? 1 : 2),
      borderDash: ctx => (isFuture(ctx) ? [2, 2] : undefined),
      // backgroundColor: ctx =>
      //   isFuture(ctx)
      //     ? isNegative(ctx)
      //       ? backgroundColorFutureNegative
      //       : backgroundColorFuturePositive
      //     : isNegative(ctx)
      //     ? backgroundColorPastNegative
      //     : backgroundColorPastPositive,
      // pointRadius: 0,
    },
  };
  return dataset;
}

function getDatasetTypeFromProjections(
  type: BudgetType,
  label: string,
  lineColor: number,
  projections: ProjectionWithAvailable[],
  groupBy: GroupBy
) {
  const color = getColor(lineColor, 'primary') || primaryColor;
  if (!projections) return;

  const dateMap = getDateMapFromProjections(projections, groupBy);

  const data: DataPoint[] = [];
  const dates = keys(dateMap).sort((a, b) => (a > b ? 1 : -1));
  dates.map(dateString => {
    let combinedBalance = 0;
    dateMap[dateString].transactions.map(p => {
      if (p.projection_type !== 'past') return;
      if (p.budget_type === type) {
        if (type === BudgetType.INCOME) {
          combinedBalance += p.amount;
        } else {
          combinedBalance -= p.amount;
        }
      }
    });
    const pastDataPoint: DataPoint = {
      x: getDateAtMidnight(dateString)?.getTime(),
      y: combinedBalance,
      metadata: [],
    };
    data.push(pastDataPoint);
  });

  const dataset: ChartDataset<'bar', ScatterDataPoint[]> = {
    type: 'bar',
    label,
    data,
    backgroundColor: color,
    borderColor: color,
    stack: type === BudgetType.INCOME ? 'stack-0' : 'stack-1',
  };
  return dataset;
}

function getGroupDataFromProjections(params: {
  groups: BudgetGroup[];
  projections: ProjectionWithAvailable[];
  groupBy: GroupBy;
}) {
  const { projections, groups, groupBy } = params;
  const dateMap = getDateMapFromProjections(projections, groupBy);
  const dates = keys(dateMap).sort((a, b) => (a > b ? 1 : -1));

  const datasetMap: Record<
    string,
    ChartDataset<'bar', ScatterDataPoint[]>
  > = {};
  const datasets: ChartDataset<'bar', ScatterDataPoint[]>[] = [];
  for (const group of groups) {
    const color = getColor(group.color, 'primary') || primaryColor;
    const income: ChartDataset<'bar', ScatterDataPoint[]> = {
      type: 'bar',
      label: group.name,
      data: [],
      backgroundColor: color,
      borderColor: color,
      stack: INCOME_AXIS_ID,
      yAxisID: INCOME_AXIS_ID,
    };
    const expense: ChartDataset<'bar', ScatterDataPoint[]> = {
      type: 'bar',
      label: group.name,
      data: [],
      backgroundColor: color,
      borderColor: color,
      stack: EXPENSE_AXIS_ID,
      yAxisID: EXPENSE_AXIS_ID,
    };
    const groupIdIncome = `${group.id}-${INCOME_AXIS_ID}`;
    const groupIdExpense = `${group.id}-${EXPENSE_AXIS_ID}`;
    datasetMap[groupIdIncome] = income;
    datasetMap[groupIdExpense] = expense;
    const budgetIds = group.budget_ids;
    for (const dateString of dates) {
      let combinedBalance = 0;
      dateMap[dateString].transactions.map(p => {
        if (p.projection_type !== 'past') return;
        if (budgetIds.includes(p.budget_id)) {
          combinedBalance += p.amount;
        }
      });
      const x = getDateAtMidnight(dateString)?.getTime();
      const pastDataPoint: DataPoint = {
        x,
        y: combinedBalance,
        metadata: [],
      };
      const pastDataPointBlank: DataPoint = {
        x,
        y: 0,
        metadata: [],
      };
      if (pastDataPoint.y > 0) {
        datasetMap[groupIdIncome].data.push(pastDataPoint);
        datasetMap[groupIdExpense].data.push(pastDataPointBlank);
      } else {
        datasetMap[groupIdExpense].data.push(pastDataPoint);
        datasetMap[groupIdIncome].data.push(pastDataPointBlank);
      }
    }
    datasets.push(datasetMap[groupIdIncome]);
    datasets.push(datasetMap[groupIdExpense]);
  }
  return datasets;
}

export function getGraphDataForAccount(params: {
  accountId: string;
  accountsById: AccountsById;
  budgetsById: BudgetsById;
  pastTransactions: TransactionDocument[];
  start: string;
  end: string;
  groupBy: GroupBy;
  todayString: string;
}) {
  const {
    accountId,
    accountsById,
    budgetsById,
    pastTransactions,
    start,
    end,
    groupBy,
    todayString,
  } = params;
  if (!budgetsById || !accountsById) {
    return {
      projections: [],
      datasets: [],
    };
  }
  const filtered_past = pastTransactions
    .filter(isBankTransaction)
    .sort(sortTransactions);
  const future =
    end > todayString
      ? getProjectedTransactions({
          budgetsById,
          start,
          end,
          todayString,
        })
      : [];
  const past = convertTransactionsToProjections(filtered_past, budgetsById);
  const projectionsByAccountId = getProjectedTransactionsByAccountId({
    accountsById,
    past,
    future,
    end,
    todayString,
  });
  const projectionsWithAvailable = projectionsByAccountId[accountId];
  const dataset = getDatasetFromProjections(
    getAccountName(accountsById[accountId]),
    accountsById[accountId].color,
    projectionsWithAvailable,
    groupBy
  );
  const datasets = dataset ? [dataset] : [];
  return { datasets, projections: projectionsWithAvailable };
}

export type CashFlowStats = {
  cashFlowStatus:
    | 'getting-out-of-debt'
    | 'going-into-debt'
    | 'going-further-in-debt'
    | 'keeping-debt'
    | 'looking-good';
  weeklyCashFlow: number;
  startingBalance: number;
  outOfMoney: string | null;
  debtFreeDate: string | null;
};
export function getCashFlowStats(params: {
  accountsById: AccountsById;
  budgetsById: BudgetsById;
  excludeBudgetIds: string[];
  todayString: string;
  endDateString: string;
}) {
  const {
    accountsById,
    budgetsById,
    excludeBudgetIds,
    todayString,
    endDateString,
  } = params;
  const accounts = keys(accountsById).map(k => accountsById[k]);
  const { total: startingBalance } = calcTotalAvailable(accounts);
  const budgets = keys(budgetsById)
    .map(k => budgetsById[k])
    .filter(b => !excludeBudgetIds.includes(b.budget_id));
  const budgetsByIdModified: BudgetsById = {};
  for (const b of budgets) {
    budgetsByIdModified[b.budget_id] = b;
  }
  const weeklyCashFlow = budgets.reduce((prev, budget) => {
    const weeklyAmount = calcFrequencyFromBudget({
      budget,
      frequency: 52,
      todayString,
    });
    return (
      prev + (budget.type === BudgetType.INCOME ? weeklyAmount : -weeklyAmount)
    );
  }, 0);

  const projections = getProjectionsForAllAccounts({
    budgetsById: budgetsByIdModified,
    endDate: endDateString,
    startingBalance,
    todayString,
  });

  let debtFreeDate: string | null = null;
  let outOfMoney: string | null = null;
  for (const [i, p] of projections.entries()) {
    const previousEntry = projections[i - 1];
    if (
      !outOfMoney &&
      p.available < 0 &&
      previousEntry &&
      previousEntry.available > 0 &&
      p.date !== previousEntry.date
    ) {
      outOfMoney = p.date;
    }

    if (
      p.available > 0 &&
      previousEntry &&
      previousEntry.available < 0 &&
      p.date !== previousEntry.date
    ) {
      debtFreeDate = p.date;
    }
  }

  let cashFlowStatus: CashFlowStats['cashFlowStatus'] = 'looking-good';
  if (startingBalance > 0) {
    if (weeklyCashFlow < 0) {
      cashFlowStatus = 'going-into-debt';
    } else if (weeklyCashFlow > 0) {
      cashFlowStatus = 'looking-good';
    } else {
      // zen
      cashFlowStatus = 'looking-good';
    }
    // will run out of money
  } else if (startingBalance < 0) {
    // debt free

    if (weeklyCashFlow < 0) {
      cashFlowStatus = 'going-further-in-debt';
    } else if (weeklyCashFlow > 0) {
      cashFlowStatus = 'getting-out-of-debt';
    } else {
      // zen
      cashFlowStatus = 'keeping-debt';
    }
  } else {
  }
  const stats: CashFlowStats = {
    cashFlowStatus,
    weeklyCashFlow,
    startingBalance,
    outOfMoney,
    debtFreeDate,
  };
  return stats;
}

export function getGraphDataForAccounts(params: {
  accountsById: AccountsById;
  budgetsById: BudgetsById;
  end: string;
  groupBy: GroupBy;
  pastTransactions: TransactionDocument[];
  startingBalance: number;
  todayString: string;
}) {
  const {
    startingBalance,
    budgetsById,
    accountsById,
    pastTransactions,
    end,
    groupBy,
    todayString,
  } = params;
  if (!budgetsById || !accountsById) {
    return {
      projections: [],
      datasets: [],
    };
  }

  const filteredPast = pastTransactions
    .filter(isBankTransaction)
    .sort((a, b) => (a.date > b.date ? -1 : 1));

  const projectionsWithAvailable = getProjectionsForAllAccounts({
    startingBalance,
    budgetsById,
    endDate: end,
    todayString,
  });
  const past = convertTransactionsToProjections(filteredPast, budgetsById);
  const pastWithAvailable = convertPastProjectionsToWithAvailable(
    past,
    startingBalance
  );

  const todayProjection: ProjectionWithAvailable = {
    past_due: false,
    date: todayString,
    available: startingBalance,
    amount: 0,
    icon: 'flash',
    color: 16,
    label: translate('TODAY'),
    projection_type: 'today',
    budget_id: null as any, // FIXME
    budget_type: null as any, // FIXME
  };
  pastWithAvailable.push(todayProjection);

  const combined: ProjectionWithAvailable[] = [
    ...pastWithAvailable,
    ...projectionsWithAvailable,
  ];

  // all accounts combined
  const dataset = getDatasetFromProjections(
    translate('COMBINED_ACCOUNTS'),
    7,
    combined,
    groupBy
  );
  const datasets: ChartDataset[] = [dataset];
  return { datasets, projections: combined };
}

export function getGraphDataForAccountsByType(params: {
  accountsById: AccountsById;
  budgetsById: BudgetsById;
  pastTransactions: TransactionDocumentWithId[];
  start: string; //@todo remove
  end: string;
  groupBy: GroupBy;
  todayString: string;
}) {
  const {
    accountsById,
    budgetsById,
    pastTransactions,
    end,
    groupBy,
    todayString,
  } = params;
  if (!budgetsById || !accountsById) return;
  const filtered_past = pastTransactions
    .filter(isBankTransaction)
    .sort((a, b) => (a.date > b.date ? -1 : 1));

  const startingBalance = calcTotalAvailable(
    dictionaryToArray(accountsById)
  ).total;
  const projectionsWithAvailable = getProjectionsForAllAccounts({
    startingBalance,
    budgetsById,
    endDate: end,
    todayString,
  });
  const past = convertTransactionsToProjections(filtered_past, budgetsById);
  const pastWithAvailable = convertPastProjectionsToWithAvailable(
    past,
    startingBalance
  );

  const combined: ProjectionWithAvailable[] = [
    ...pastWithAvailable,
    ...projectionsWithAvailable,
  ];

  const sorted = combined.sort((a, b) => (a.date > b.date ? -1 : 1));
  // all accounts combined
  const incomeData = getDatasetTypeFromProjections(
    BudgetType.INCOME,
    translate('OG_INCOME'),
    7,
    sorted,
    groupBy
  );
  const savingsData = getDatasetTypeFromProjections(
    BudgetType.SAVINGS,
    translate('SAVING_S', 2),
    budgetTypes.SAVINGS.color,
    sorted,
    groupBy
  );
  const billData = getDatasetTypeFromProjections(
    BudgetType.BILL,
    translate('BILL_S', 2),
    budgetTypes.BILL.color,
    sorted,
    groupBy
  );
  const spendingData = getDatasetTypeFromProjections(
    BudgetType.SPENDING,
    translate('SPENDING'),
    budgetTypes.SPENDING.color,
    sorted,
    groupBy
  );
  const cashFlowData = combineData(
    incomeData?.data ?? [],
    savingsData?.data ?? [],
    billData?.data ?? [],
    spendingData?.data ?? []
  );

  const datasets: ChartDataset[] = [
    cashFlowData,
    incomeData,
    savingsData,
    billData,
    spendingData,
  ].filter(isPresent);

  return {
    datasets,
    projections: combined,
  };
}

export function getGraphDataForAccountsByBudgetView(params: {
  accountsById: AccountsById;
  budgetsById: BudgetsById;
  pastTransactions: TransactionDocument[];
  start: string; // @todo remove
  end: string;
  budgetView: BudgetViewDocument;
  groupBy: GroupBy;
  todayString: string;
}) {
  const {
    accountsById,
    budgetsById,
    pastTransactions,
    end,
    budgetView,
    groupBy,
    todayString,
  } = params;
  if (!budgetsById || !accountsById) {
    const blank: {
      datasets: ChartDataset<'line', ScatterDataPoint[]>[];
      projections: [];
      transactionsByDateGroup: [];
    } = {
      datasets: [{ data: [] }],
      projections: [],
      transactionsByDateGroup: [],
    };
    return blank;
  }
  const filtered_past = pastTransactions
    .filter(isBankTransaction)
    .sort((a, b) => (a.date > b.date ? -1 : 1));

  const startingBalance = calcTotalAvailable(
    keys(accountsById).map(k => accountsById[k])
  ).total;
  const projectionsWithAvailable = getProjectionsForAllAccounts({
    startingBalance,
    budgetsById,
    endDate: end,
    todayString,
  });
  const past = convertTransactionsToProjections(filtered_past, budgetsById);
  const pastWithAvailable = convertPastProjectionsToWithAvailable(
    past,
    startingBalance
  );

  const combined: ProjectionWithAvailable[] = [
    ...pastWithAvailable,
    ...projectionsWithAvailable,
  ];

  const sorted = combined.sort((a, b) => (a.date > b.date ? -1 : 1));
  // all accounts combined

  const groupData = getGroupDataFromProjections({
    groups: budgetView.groups,
    projections: sorted,
    groupBy,
  });
  const cashFlowData = getCashFlowData(groupData.map(g => g.data));

  const datasets: (
    | ChartDataset<'line', ScatterDataPoint[]>
    | ChartDataset<'bar', ScatterDataPoint[]>
  )[] = groupData.length > 1 ? [cashFlowData, ...groupData] : groupData;
  return {
    datasets,
    projections: combined,
  };
}

function getCashFlowData(dataArray: ScatterDataPoint[][]) {
  const data: DataPoint[] = dataArray[0].map((d, index) => {
    let cashFlow = d.y;
    for (var i = 1; i < dataArray.length; i++) {
      cashFlow += dataArray[i][index].y;
    }
    const point: DataPoint = {
      x: d.x,
      y: cashFlow,
    };
    return point;
  });

  const combinedData: ChartDataset<'line', ScatterDataPoint[]> = {
    type: 'line',
    label: translate('CASH_FLOW'),
    data,
    backgroundColor: getTextColor(),
    borderColor: getTextColor(),
    fill: false,
    pointRadius: 0,
    yAxisID: CASH_FLOW_AXIS_ID,
    segment: {
      borderWidth: ctx => (isFuture(ctx) ? 1 : 2),
      borderDash: ctx => (isFuture(ctx) ? [20, 5] : undefined),
    },
  };
  return combinedData;
}

function combineData(
  incomeData: ScatterDataPoint[],
  savingsData: ScatterDataPoint[],
  billsData: ScatterDataPoint[],
  spendingData: ScatterDataPoint[]
) {
  const data: DataPoint[] = incomeData.map((d, index) => {
    const income = d.y;
    const bills = billsData[index].y;
    const savings = savingsData[index].y;
    const spending = spendingData[index].y;
    const combined = income - savings - bills - spending;
    const point: DataPoint = {
      x: d.x,
      y: combined,
    };
    return point;
  });
  const combinedData: ChartDataset<'line', ScatterDataPoint[]> = {
    type: 'line',
    label: translate('PLANNED_CASH_FLOW'),
    data,
    backgroundColor: getTextColor(),
    borderColor: getTextColor(),
    fill: true,
    pointRadius: 0,
    segment: {
      borderWidth: ctx => (isFuture(ctx) ? 1 : 2),
      borderDash: ctx => (isFuture(ctx) ? [20, 5] : undefined),
    },
  };
  return combinedData;
}

function convertTransactionsToProjections(
  past_transactions: TransactionDocument[],
  budgets_by_id: BudgetsById
) {
  const projections: Projection[] = [];
  past_transactions.map(t => {
    t.budgets.map(split => {
      const budget_id = split.child_budget_id ?? split.budget_id;
      if (budget_id === IGNORE_BUDGET_ID) return;
      const budget = budgets_by_id[budget_id];
      if (!budget) return;
      const projection: Projection = {
        past_due: false,
        amount: split.amount,
        label: t.name_custom ?? t.name,
        color: budget.color,
        icon: budget.icon,
        date: t.date,
        projection_type: 'past',
        account_id: t.account_id,
        budget_id: budget_id,
        budget_type: budget.type,
      };
      projections.push(projection);
    });
  });
  return projections;
}

function convertPastProjectionsToWithAvailable(
  past_transactions: Projection[],
  starting_balance: number
) {
  let balance = starting_balance;
  const reversed = past_transactions
    .map(t => {
      const projectionWithAvailable: ProjectionWithAvailable = {
        ...t,
        available: balance,
      };
      balance -= t.amount;
      return projectionWithAvailable;
    })
    .reverse();
  return reversed;
}

function getDateMapFromProjections(
  projections: ProjectionWithAvailable[],
  groupBy: GroupBy
) {
  const dateMap: {
    [date: string]: { transactions: ProjectionWithAvailable[] };
  } = {};
  projections.map(projection => {
    const dateString = getDateStringForProjection(projection);
    // month
    const groupKey = getGroupByKeyFromDate(groupBy, dateString);
    if (!dateMap[groupKey]) {
      dateMap[groupKey] = { transactions: [] };
    }
    dateMap[groupKey].transactions.push(projection);
  });
  return dateMap;
}

const isNegative = (ctx: ScriptableLineSegmentContext) => {
  const negative = ctx.p0.parsed.y <= 0 && ctx.p1.parsed.y <= 0;
  return negative;
};
